import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { hearComLogoBase64 } from '../hearComLogoBase64';

const generatePdf = (formData, customerSignature) => {
	return new Promise((resolve, reject) => {
		try {
			const doc = new jsPDF();
			const logoWidth = 30;
			const logoHeight = 12;
			const pageWidth = doc.internal.pageSize.getWidth();
			const pageHeight = doc.internal.pageSize.getHeight();
			const margin = 10;
			const spacing = 5; // Adjusted spacing for less space between paragraphs
			let startY = margin;

			// Add logo
			doc.addImage(hearComLogoBase64, 'PNG', pageWidth - logoWidth - margin, startY, logoWidth, logoHeight);

			// Title
			doc.setFontSize(16);
			doc.text('Supplies Form', margin, startY + logoHeight / 2 + 5);
			startY += logoHeight + spacing;

			// Add basic form data
			doc.autoTable({
				startY: startY,
				head: [['Field', 'Value']],
				body: [
					['Customer Name', formData.customerName],
					['Customer Phone', formData.customerPhone],
					['Customer Email', formData.customerEmail],
					['Hearing Specialist Name', formData.providerName]
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Product Specification
			doc.autoTable({
				startY: startY,
				head: [['Product Specification:', 'Selected']],
				body: [
					['Streamline Mic', formData.streamLineMic ? 'Yes' : 'No'],
					['Streamline TV', formData.streamlineTV ? 'Yes' : 'No'],
					['Charger', formData.charger ? 'Yes' : 'No'],
					['Concha Locks', formData.conchaLocks ? 'Yes' : 'No'],
					['Wax Guards', formData.waxGuards ? 'Yes' : 'No'],
					['Domes/ Click-Sleeves', formData.domesSleeves ? 'Yes' : 'No'],
					['Batteries (6-month supplies)', formData.batteries ? 'Yes' : 'No'],
					['Receivers', formData.receivers ? 'Yes' : 'No']
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Customer Acknowledgement
			doc.autoTable({
				startY: startY,
				head: [['Customer Acknowledgement']],
				body: [
					[
						{
							content: `
              Fees:
              - The prices may vary depending on the manufacturer. 

              Disclaimer:
              - I acknowledge this is not a purchase. The hear.com support team will reach out with a quote for the supply order.
            `,
							colSpan: 1,
							styles: { halign: 'left' }
						}
					]
				],
				margin: { left: margin },
				headStyles: { fillColor: [0, 92, 158], textColor: [255, 255, 255], fontStyle: 'bold' },
				styles: { fontSize: 8, cellPadding: 2, halign: 'left' }
			});
			startY = doc.lastAutoTable.finalY + spacing;

			// Final text and special note
			doc.setFontSize(8);
			const finalText = `
        By signing below, customer agrees to the above-mentioned terms and authorizes the request for a quote of supply order.
      `;
			const splitFinalText = doc.splitTextToSize(finalText, pageWidth - 2 * margin);
			doc.text(splitFinalText, margin, startY);
			startY += splitFinalText.length * 3;

			// Calculate positions for signature and date
			const signatureHeight = 12;
			const signatureWidth = 40;
			const bottomMargin = 30; // Adjust based on required spacing from the bottom
			const signaturePositionY = pageHeight - bottomMargin - signatureHeight;
			const datePositionY = pageHeight - bottomMargin;

			// Add signatures
			if (customerSignature) {
				doc.addImage(customerSignature, 'PNG', margin, signaturePositionY, signatureWidth, signatureHeight);
				doc.text('Customer Signature', margin, signaturePositionY + signatureHeight + 5);
			}

			// Add executed date in the bottom right corner
			const executedDate = formData.executedDate;
			doc.text(`Executed on: ${executedDate}`, pageWidth - margin - 50, datePositionY);

			// Convert to Blob and resolve the promise
			const pdfBlob = doc.output('blob');
			resolve(pdfBlob);
		} catch (error) {
			reject(error);
		}
	});
};

export default generatePdf;
