import React, { useState, useEffect, useRef } from 'react';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import SignaturePad from '../components/Signature';
import generatePdf from '../components/SuppliesPdfGenerator';
import { parsePhoneNumber } from '../utils/libphonenumber-js.custom';

const SuppliesShop = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const clouflareSuppliesWorker = 'https://casemanagement-form.audibene.workers.dev/supply-form';

	const [formData, setFormData] = useState({
		customerName: '',
		customerPhone: '',
		customerEmail: '',
		providerName: '',
		streamLineMic: false,
		streamlineTV: false,
		charger: false,
		conchaLocks: false,
		waxGuards: false,
		domesSleeves: false,
		batteries: false,
		receivers: false,
		trialPeriod: false,
		warrantyPeriod: false,
		acknowledge: false,
		executedDate: ''
	});
	const [customerSignature, setCustomerSignature] = useState('');
	const [customerPhoneError, setCustomerPhoneError] = useState('');
	const [customerEmailError, setCustomerEmailError] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [successMessageColor, setSuccessMessageColor] = useState('');
	const [signatureError, setSignatureError] = useState('');
	const [loading, setLoading] = useState(false);
	const customerPhoneInput = useRef(null);
	const customerEmailInput = useRef(null);
	const signaturePadRef = useRef(null);

	const validatePhoneNumber = (phoneNumberString, setError) => {
		const phoneNumber = parsePhoneNumber(phoneNumberString, 'US');
		if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'US') {
			setError('');
			return true;
		} else {
			setError('is-warning');
			return false;
		}
	};

	const validateEmail = (email, setError) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailRegex.test(email)) {
			setError('');
			return true;
		} else {
			setError('is-warning');
			return false;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const signature = signaturePadRef.current.getSignature();
		if (!signature) {
			setSignatureError('Please sign before submitting.');
			return;
		}
		setCustomerSignature(signature);
		setSignatureError('');

		const customerPhoneNumberString = customerPhoneInput.current.value;
		const isCustomerPhoneValid = validatePhoneNumber(customerPhoneNumberString, setCustomerPhoneError);

		const customerEmailString = customerEmailInput.current.value;
		const isCustomerEmailValid = validateEmail(customerEmailString, setCustomerEmailError);

		if (!isCustomerPhoneValid) {
			customerPhoneInput.current.focus();
			return;
		} else if (!isCustomerEmailValid) {
			customerEmailInput.current.focus();
			return;
		}

		setLoading(true);

		const pdfBlob = await generatePdf(formData, signature);

		const formDataToSend = new FormData();
		formDataToSend.append('customerEmail', formData.customerEmail);
		formDataToSend.append('file', pdfBlob, 'supplies-form.pdf');
		formDataToSend.append('executedDate', formData.executedDate);

		try {
			const response = await fetch(clouflareSuppliesWorker, {
				method: 'POST',
				body: formDataToSend
			});

			if (response.ok) {
				resetForm();
				window.location.href = `/success-supplies/`;
			} else {
				setSuccessMessage('There was an error submitting the form. Please try again.');
				setSuccessMessageColor('red');
			}
		} catch (error) {
			setSuccessMessage('There was an error submitting the form. Please try again.');
			setSuccessMessageColor('red');
		} finally {
			setLoading(false);
		}
	};

	const resetForm = () => {
		setFormData({
			customerName: '',
			customerPhone: '',
			customerEmail: '',
			providerName: '',
			streamLineMic: false,
			streamlineTV: false,
			charger: false,
			conchaLocks: false,
			waxGuards: false,
			domesSleeves: false,
			batteries: false,
			receivers: false,
			trialPeriod: false,
			warrantyPeriod: false,
			acknowledge: false,
			executedDate: new Date().toISOString().split('T')[0]
		});
		setCustomerSignature('');
		setCustomerPhoneError('');
		setCustomerEmailError('');
		customerPhoneInput.current.value = '';
		customerEmailInput.current.value = '';
		document.querySelectorAll('input[type=checkbox]').forEach((el) => (el.checked = false));
		document.querySelectorAll('input[type=text]').forEach((el) => (el.value = ''));
		document.querySelectorAll('input[type=tel]').forEach((el) => (el.value = ''));
	};

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		if (type === 'checkbox') {
			setFormData({ ...formData, [name]: checked });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	useEffect(() => {
		const today = new Date();
		const formattedDate = today.toISOString().split('T')[0];
		setFormData({ executedDate: formattedDate });
	}, []);

	return (
		<Layout>
			<Seo
				title="Buy hear.com hearing aids accessories online | hear.com"
				description="Shop online for hear.com hearing aid accessories like batteries, chargers, domes, and more. Fast, easy, and convenient shopping experience."
				noindex={true}
			/>
			<div className="o-row-4p5">
				<div className="o-wrapper o-wrapper--small">
					<div className="o-row-4p5"></div>
					<div className="o-row-2p5">
						<CustomBreadcrumb location={location} crumbs={crumbs} />
					</div>
					<div className="o-row-2">
						<h2 className="c-returns-headline">Supplies Request Form</h2>
					</div>
					<div className="o-row-2">
						<p className="c-md-p">Please complete and sign this form to submit your request.</p>
					</div>

					<form className="c-loss-form" onSubmit={handleSubmit}>
						<div className="o-row-2">
							<div className="u-flex">
								<div className="c-loss-customer">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-name" className="c-loss-form-info-label">
												Customer Name:
											</label>
											<input
												type="text"
												id="customer-name"
												name="customerName"
												placeholder="Customer Name:"
												className="c-loss-form-info-input"
												required
												value={formData.customerName}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-phone" className="c-loss-form-info-label">
												Customer Phone Number:
											</label>
											<input
												ref={customerPhoneInput}
												className={`c-loss-form-info-input ${customerPhoneError ? customerPhoneError : ''}`}
												type="tel"
												id="customer-phone"
												name="customerPhone"
												placeholder="Phone Number +1"
												required
												value={formData.customerPhone}
												onChange={handleInputChange}
											/>
											{customerPhoneError && <p className="c-validation-message">Please enter a valid phone number.</p>}
										</div>
									</div>
								</div>
								<div className="c-loss-provider">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="provider-name" className="c-loss-form-info-label">
												Hearing Specialist Name:
											</label>
											<input
												type="text"
												id="provider-name"
												name="providerName"
												placeholder="Hearing Specialist Name"
												className="c-loss-form-info-input"
												required
												value={formData.providerName}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-email" className="c-loss-form-info-label">
												Customer Email Address:
											</label>
											<input
												ref={customerEmailInput}
												className={`c-loss-form-info-input ${customerEmailError ? customerEmailError : ''}`}
												type="email"
												id="customer-email"
												name="customerEmail"
												placeholder="Email Address"
												required
												value={formData.customerEmail}
												onChange={handleInputChange}
											/>
											{customerEmailError && (
												<p className="c-validation-message">Please enter a valid email address.</p>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<p className="c-md-p">Please specify below the products you are interested in purchasing.</p>
						</div>

						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p>Product Specification (Select all that apply):</p>
								</div>
								<div className="c-returns-form-group__two-col">
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Streamline Mic
										<input
											type="checkbox"
											name="streamLineMic"
											checked={formData.streamLineMic}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Streamline TV
										<input
											type="checkbox"
											name="streamlineTV"
											checked={formData.streamlineTV}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Charger
										<input type="checkbox" name="charger" checked={formData.charger} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Concha locks
										<input
											type="checkbox"
											name="conchaLocks"
											checked={formData.conchaLocks}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
								</div>
								<div className="c-returns-form-group__two-col c-returns-form-group__two-col--lossanddamage-pt0">
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Wax Guards
										<input type="checkbox" name="waxGuards" checked={formData.waxGuards} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Domes/ Click-Sleeves
										<input
											type="checkbox"
											name="domesSleeves"
											checked={formData.domesSleeves}
											onChange={handleInputChange}
										/>
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Batteries (6-month supplies)
										<input type="checkbox" name="batteries" checked={formData.batteries} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
									<label className="c-returns-form-group__checkbox c-returns-form-group__checkbox--alt">
										Receivers
										<input type="checkbox" name="receivers" checked={formData.receivers} onChange={handleInputChange} />
										<span className="c-returns-form-group__checkbox-checkmark"></span>
									</label>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<div className="c-returns-form-group">
								<div className="c-returns-form-group__title">
									<p> Customer Acknowledgement:</p>
								</div>
								<div className="c-returns-form-group__two-col c-returns-form-group__two-col--lossanddamage">
									<ul>
										<p className="c-supplies-title">Fees:</p>
										<li style={{ marginBottom: '10px' }}>
											The prices may vary depending on the manufacturer.
										</li>
										<p className="c-supplies-title">Disclaimer:</p>
										<label className="c-supplies-checkmark__checkbox-label">
											<input className="c-supplies-checkmark__checkbox-label__checkbox" type="checkbox" required /> I
											acknowledge this is not a purchase. The hear.com support team will reach out with a quote for the
											supply order.
										</label>
									</ul>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<p className="c-md-p">
								<strong>
									By signing below, I agree to the above-mentioned terms and authorize the request for a quote of supply
									order.
								</strong>
							</p>
						</div>

						<div className="o-row-2">
							<div className="u-flex">
								<div className="c-loss-customer">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="customer-signature" className="c-loss-form-info-label">
												Customer Signature:
											</label>
											<SignaturePad
												ref={signaturePadRef}
												setSignature={setCustomerSignature}
												clearSignatureError={() => setSignatureError('')}
											/>
											{signatureError && <p style={{ color: 'red' }}>{signatureError}</p>}
										</div>
									</div>
								</div>
								<div className="c-loss-provider">
									<div className="o-row-2">
										<div className="c-loss-form-info-row">
											<label htmlFor="executed-date" className="c-loss-form-info-label">
												Executed on date of
											</label>
											<input
												type="date"
												id="executed-date"
												name="executedDate"
												className="c-loss-form-info-input"
												value={formData.executedDate}
												readOnly
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="o-row-2">
							<button type="submit" className="c-cta c-cta--center-desktop">
								Submit
							</button>
						</div>
						{loading && <div className="c-loss-spinner"></div>}
						{successMessage && <p style={{ color: successMessageColor, textAlign: 'center' }}>{successMessage}</p>}
					</form>
				</div>
			</div>
		</Layout>
	);
};

export default SuppliesShop;
